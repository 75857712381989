import MicroModal from "micromodal";

var uploadPhotosTrigger,
    mesPhotosQuestion,
    uploadPhotosForm,
    fileInput,
    dossierReference,
    questionId,
    responseContainer,
    responseId,
    picture,
    showIcon,
    uploadIcon,
    deleteIcon,
    textPicture,
    errorsContainer,
    mesPhotosResponseForm,
    responseValue,
    sectionResponseValue,
    uploadContainer;
var atleastOneSectionHasYes = false;
$(document).ready(function () {

    function questionsGlobalCheck(imageUploaded = false) {
        $(".mesPhotosQuestion").each(function () {
            $(this).find(".question").each(function () {
                let val = $(this).find("input[type='radio']:checked").val();
                let hasFile = $(this).find('.photo-container .picture').data('has-file-name');
                if (val == 0) {
                    $(this).find('.radio-btn.left').addClass('error');
                    $(this).find('.upload-image-error').html("")
                } else if(val == 1 && hasFile == false) {
                    $(this).find('.upload-image-error').html("Veuillez importer une photo")
                    $(this).find('.radio-btn.left').removeClass('error');
                }
                if (imageUploaded == true) {
                    $(this).find('.upload-image-error').html("");
                }
            })
        })
    }
    questionsGlobalCheck();

    function displayLoadingOverlay(element) {
        element.LoadingOverlay("show", {imageColor: "#1b284f"});
    }

    function hideLoadingOverlay(element) {
        element.LoadingOverlay("hide");
    }

    $('.upload-photo-trigger').on('click', function (e) {
        uploadPhotosTrigger = $(this);

        mesPhotosQuestion = uploadPhotosTrigger.closest(".mesPhotosQuestion");
        uploadContainer = mesPhotosQuestion.find('.photo-container');
        uploadPhotosForm = mesPhotosQuestion.find(".upload-photos-form").find('form');
        fileInput = uploadPhotosForm.find('input[type="file"]');
        dossierReference = uploadPhotosForm.find('input:hidden[name*=dossier_reference]').val();
        questionId = uploadPhotosForm.find('input:hidden[name*=question_id]').val();
        responseContainer = uploadPhotosForm.find('input:hidden[name*=response_id]');
        responseId = responseContainer.val();

        picture = mesPhotosQuestion.find(".picture");
        showIcon  = mesPhotosQuestion.find(".show-icon");
        uploadIcon  = mesPhotosQuestion.find(".upload-icon");
        deleteIcon  = mesPhotosQuestion.find(".delete-icon");
        textPicture  = mesPhotosQuestion.find(".text-picture");

        errorsContainer = mesPhotosQuestion.find(".upload-image-error")

        fileInput.click();
        fileInput.off();

        fileInput.on('change', function (e) {
            uploadPhotosForm.submit();
            $(this).val("");
        });

        uploadPhotosForm.submit(function (e) {
            displayLoadingOverlay(uploadContainer);
            $.ajax({
                url: responseId ? Routing.generate('api_photos_upload_and_delete', {
                        'reference': dossierReference,
                        'question_id': questionId,
                        'response_id': responseId,
                    }) :
                    Routing.generate('api_photos_upload', {
                        'reference': dossierReference,
                        'question_id': questionId,
                        'response_id': responseId,
                    }),
                type: 'post',
                data: new FormData(uploadPhotosForm[0]),
                cache: false,
                contentType: false,
                processData: false
            }).statusCode({
                500: function (data) {
                    errorsContainer.text("Un problème est survenu, veuillez reéssayer plus tard");
                    hideLoadingOverlay(uploadContainer);
                },
                400: function (data) {
                    errorsContainer.text("Un problème est survenu, veuillez reéssayer plus tard");
                    hideLoadingOverlay(uploadContainer);
                },
                200: function (data) {
                    var src = Routing.generate('core_dossier_photo_view', {"reference" : dossierReference, "response_id": data.responseId}) + `?v=${new Date().getTime()}`
                    var imgElm = '';
                    if (data.fileMimeType == 'application/pdf') {
                        imgElm = '<object class="picture pdf" data-has-file-name="true" data="'+ src +'" ></object>';
                    } else {
                        imgElm = '<img class="picture jpg" data-has-file-name="true" src="'+ src +'" />';
                    }
                    uploadContainer.find('.img-wrapper').html(imgElm);
                    showIcon.attr("href", Routing.generate('core_dossier_photo_view', {"reference" : dossierReference, "response_id": data.responseId})).removeClass("hidden");
                    uploadIcon.attr("href", Routing.generate('core_dossier_photo_download', {"reference" : dossierReference, "response_id": data.responseId})).removeClass("hidden");
                    deleteIcon.attr("href", Routing.generate('core_dossier_photo_remove', {"reference" : dossierReference, "response_id": data.responseId})).removeClass("hidden");
                    textPicture.text("Votre photo");
                    responseContainer.val(data.responseId);
                    uploadPhotosTrigger.text("Modifier la photo");
                    questionsGlobalCheck(true);
                    hideLoadingOverlay(uploadContainer);
                }
            });
            $(this).off();
            return false;
        });
    });

    $(".mes-photos-response-form").find('input[type*=radio]').on('change', function (e) {
        responseValue = $(this).val();

        uploadPhotosForm = $(this).closest(".mesPhotosQuestion").find(".upload-photos-form").find('form');
        dossierReference = uploadPhotosForm.find('input:hidden[name*=dossier_reference]').val();
        questionId = uploadPhotosForm.find('input:hidden[name*=question_id]').val();
        responseContainer = uploadPhotosForm.find('input:hidden[name*=response_id]');
        responseId = responseContainer.val();

        mesPhotosResponseForm = $(this).closest(".mesPhotosQuestion").find(".mes-photos-response-form").find('form');

        mesPhotosResponseForm.on('submit', function (e) {
            e.preventDefault();
            $.ajax({
                url: Routing.generate('api_mes_photos_response_save', {
                    'reference': dossierReference,
                    'question_id': questionId,
                    'response_id': responseId,
                    "response_value" : responseValue
                }),
                type: 'post',
                cache: false,
                contentType: false,
                processData: false
            }).statusCode({
                200: function (data) {
                    responseContainer.val(data.responseId);
                    questionsGlobalCheck();
                }
            });

            $(this).off();
            return false;
        });

        mesPhotosResponseForm.submit();
    });

    $(".sectionDisplay").each(function () {
        var globalChildQuestionRadioValus = [];
        dossierReference = $('input:hidden[name*=dossier_reference]').val();
        mesPhotosQuestion = $(this).closest('.mesPhotosQuestion');
        mesPhotosQuestion.find('.question input[type*=radio]:checked').each(function () {
            globalChildQuestionRadioValus.push($(this).val());
        })
        if (globalChildQuestionRadioValus.length > 0) {
            $(this).find("input[type*=radio][value='1']").trigger('click');
        }

        $(this).find('input[type*=radio]').on('change', function (e) {
            sectionResponseValue = $(this).val();
            var childQuestionRadioValus = [];
            mesPhotosQuestion = $(this).closest('.mesPhotosQuestion')
            uploadContainer = mesPhotosQuestion.find('.photo-container');
            mesPhotosQuestion.find('.question input[type*=radio]:checked').each(function () {
                childQuestionRadioValus.push($(this).val());
            })

            if (sectionResponseValue == 1) {
                mesPhotosQuestion.find('.question').show(500);
            } else {
                event.preventDefault();

                if (childQuestionRadioValus.length === 0) {
                    $(this).closest('.mesPhotosParentQuestion').find('.question').hide(500);
                } else {
                    var href = $(this).attr('data-href');
                    var popupDiv =  $('<div>' +
                        '<h1 class="category-title" style="margin-top: -20px;">Suppression de vos réponses</h1>' +
                        '<div class="content">' +
                        '<p class="fs-15 pull-left">Attention : En cliquant sur <b>OUI</b>, vos réponses à la section seront définitivement supprimées. Sinon, cliquez sur <b>NON</b>.</p>' +
                        '</div>' +
                        '<br><div class="btn-container">' +
                        '<div class="marypopin-close btn blue" style="width: 50%;">NON</div>' +
                        '<a href="'+href+'" class="btn remove-photo-confirmed" style="width: 45%;">OUI</a>' +
                        '</div>' +
                        '</div>');
                    $(popupDiv).dialogMp();
                    $('.remove-photo-confirmed').on('click', function (e) {
                        e.preventDefault();
                        updateApiMesPhotoResponse(dossierReference, $(this).attr('href'));
                        displayLoadingOverlay(uploadContainer);
                        localStorage.setItem('quez-ans_'+dossierReference, 'NON');
                        $('#marypopin-mask').hide();
                    })
                    $(this).siblings("input[type*=radio][value='1']").trigger('click');
                }
            }
            //update dossier.mesPhotosControlParentQuezCheckedToNo
            if ($(".sectionDisplay").length === $(".sectionDisplay").find("input[type*=radio][value='0']:checked").length) {
                var elm = $(this).closest('.mesPhotosParentQuestion');
                displayLoadingOverlay(elm);
                updateApiMesPhotoResponse(dossierReference);
            }
        });
    })

    let updateApiMesPhotoResponse = function(dossierReference, href = false) {
        $.ajax({
            url: Routing.generate('api_mes_photos_no_response_update', {
                'reference': dossierReference,
            }),
            type: 'post',
            cache: false,
            contentType: false,
            processData: false
        }).statusCode({
            200: function (data) {
                // Refresh the page
                if (href) {
                    window.location.href = href;
                } else {
                    location.reload();
                }
            },
            403: function (data) {
                hideLoadingOverlay(elm);
            },
        });
    }

    // check  atleast on of the section is answered to OUI
    $(".sectionDisplay").each(function () {
        if ($(this).find('input[type*=radio]:checked').val() == 1) {
            atleastOneSectionHasYes = true;
        }
    })
    // keep checked reste of the section responses to NON, if atleast on of them is answered to OUI
    $(".sectionDisplay").each(function () {
        if ($(this).find('input[type*=radio]:checked').val() != 1 && atleastOneSectionHasYes == true) {
            $(this).find("input[type*=radio][value='0']").trigger('click');
        }
    })

    $(document).on('click', ".delete-icon", function (e) {
        var href = $(this).attr('href');
        var popupDiv =  $('<div>' +
            '<h1 class="category-title" style="margin-top: -20px;">Confirmez-vous la suppression de votre image ? </h1>' +
            '<div class="content">' +
            '<p class="fs-16 pull-left">Attention : En cliquant sur <b>OUI</b>, le document sera définitivement supprimé. Sinon, cliquez sur <b>NON</b>.</p>' +
            '</div>' +
            '<br><div class="btn-container">' +
            '<div class="marypopin-close btn blue" style="width: 50%;">NON</div>' +
            '<a href="'+href+'" class="btn remove-photo-confirmed" style="width: 45%;">OUI</a>' +
            '</div>' +
            '</div>');
        $(popupDiv).dialogMp();
        event.preventDefault();
        uploadContainer = $(this).closest('.photo-container');
        $('.remove-photo-confirmed').on('click', function () {
            displayLoadingOverlay(uploadContainer);
            $('#marypopin-mask').hide();
        })
        return false;
    });

    // for bar-th-171/172 ticket PE-760 // set default value as OUI,OUI
    let quezElem = $('#question_15, #question_16').find('.sectionDisplay');
    let quezElemQuestion = $('#question_15, #question_16').find('.question');
    quezElem.find('input[type*=radio]').on('change', function () {
        if ($(this).val() == 1) {
            localStorage.clear();
            quezElemQuestion.find('.radio-btn.left').trigger('click');
        }
    })
    // by default set to OUI
    // setTimeout(() => {
        if (quezElem.length > 0 && localStorage.getItem('quez-ans_'+dossierReference) !== 'checkedtonon') {
            quezElem.find('.radio-btn.left').trigger('click');
            quezElemQuestion.find('.radio-btn.left').trigger('click');
        }
    // }, 3000);
    //
});